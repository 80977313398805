import "./Footer.css";
import React from 'react';
class Footer extends React.Component {
    render() {
        return(
            <footer id="footer">
                <p>
                    Copyright © 2023, Romit Patel
                </p>
            </footer>
        )
}
}

export default Footer; 